import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import ContentfulRichText from '../components/contentfulRichText';
import Layout from '../components/layout';
import { Seo } from '../components/seo';

const InfoPage = ({ data }) => {
  const scrollIntoView = (elementId) => {
    document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Layout>
      <main className="pb-16 px-8">
        <header className="h-screen py-16 flex items-center mx-auto text-xl md:text-6xl leading-snug z-10">
          <div className="container mx-auto">
            {data.contentfulAboutPage.sections.map((section, index) => (
              <React.Fragment key={index}>
                <button
                  className={`font-bold hover:font-light`}
                  onClick={() => scrollIntoView(`section${index}`)}
                >
                  {section.title}
                </button>
                <>
                  {` `}
                  <span className="inline-block mx-4">/</span>
                  {` `}
                </>
              </React.Fragment>
            ))}

            <Link className={`font-bold hover:font-light`} to={`/visit`}>
              Our Neighborhood
            </Link>
            <>
              {` `}
              <span className="inline-block mx-4">/</span>
              {` `}
            </>

            <Link className={`font-bold hover:font-light`} to={`/hello`}>
              Schedule An Appointment
            </Link>
          </div>
        </header>

        <div>
          {data.contentfulAboutPage.sections.map((section, index) => (
            <section
              className="container mx-auto pt-16 overflow-hidden"
              id={`section${index}`}
            >
              <h2 className="text-4xl font-bold uppercase mb-4">
                {section.title}
              </h2>
              <ContentfulRichText richText={section.content} />

              <div className="mt-16 flex justify-center">
                <div className="md:flex items-center mx-auto">
                  <GatsbyImage
                    alt={section.title}
                    image={section.images[0].gatsbyImage}
                    className="mb-8 md:mb-0"
                  />
                  <GatsbyImage
                    alt={section.title}
                    image={section.images[1].gatsbyImage}
                    className="-ml-8"
                  />
                </div>
              </div>
            </section>
          ))}
        </div>
      </main>
    </Layout>
  );
};

export default InfoPage;

export const Head = ({ data }) => (
  <Seo
    title={data.contentfulAboutPage.meta.title}
    description={data.contentfulAboutPage.meta.description}
  />
);

export const query = graphql`
  {
    contentfulAboutPage(contentful_id: { eq: "L8hwP4mwHge5aa6nnkvCq" }) {
      meta {
        title
        description
      }
      sections {
        title
        content {
          raw
        }
        images {
          gatsbyImage(width: 640)
        }
      }
    }
  }
`;
